import { DocumentDto } from '@/models';
import { BulkAction } from '@/models/BulkAction.model';
import {
  BulkEmployerListResponse,
  BulkEmployerValidateResponse,
  EmployerListReportResponse
} from '@/models/BulkEmployer.model';
import {
  BulkUploadPreviewResponse,
  Plan,
  PlanInfo
} from '@/models/BulkUploadPreviewResponse.model';
import { DocumentKey } from '@/models/DocumentKey.model';

import * as yup from 'yup';

import ApiService from './Api.service';

export type BulkWelcomeEmailTargetRecords = {
  date: string;
  planId: string;
}[];

export const BulkWelcomeEmailsPlansSchema = yup.array().of(
  yup.object().shape({
    date: yup
      .date()
      .required('Date is required')
      .min(new Date(), 'Date must be in the future'),
    planId: yup.string().required('Plan ID is required')
  })
);

export type BulkWelcomeEmailsPlansTable = yup.InferType<
  typeof BulkWelcomeEmailsPlansSchema
>;
class ActionCenterService {
  static async getDocumentKeys(entity: string): Promise<DocumentKey[]> {
    return ApiService.getJson(`/action-center/document/keys`, { entity });
  }

  static async bulkUploadPlanDocumentsPreview(
    documentData: FormData
  ): Promise<BulkUploadPreviewResponse> {
    return ApiService.postFormData(
      '/action-center/bulk/upload/documents/preview',
      documentData
    );
  }

  static async bulkUploadPlanDocuments(
    folderId: string,
    effectiveDate?: string
  ): Promise<BulkAction> {
    return ApiService.postJson('/action-center/bulk/upload/documents', {
      effectiveDate,
      folderId
    });
  }

  static async getBulkUploadActions(): Promise<BulkAction[]> {
    return ApiService.getJson('/action-center/bulk/upload');
  }

  static async getBulkEmailActions(): Promise<BulkAction[]> {
    return ApiService.getJson('/action-center/bulk/email');
  }

  static async getDocument(documentId: string | number): Promise<DocumentDto> {
    return ApiService.getJson(`/action-center/document/${documentId}`);
  }

  static async getBulkWelcomeEmailActions(): Promise<BulkAction[]> {
    return ApiService.getJson('/action-center/bulk/bulk-welcome-email');
  }

  static async getAnnualNoticesActions(): Promise<BulkAction[]> {
    return ApiService.getJson('/action-center/bulk/annual-notice');
  }

  static async bulkEmailPlansPreview(effectiveDate: string): Promise<Plan[]> {
    return ApiService.postJson('/action-center/bulk/email/plans/preview', {
      effectiveDate
    });
  }

  static async annualNoticePlansPreview(
    planIds: [string]
  ): Promise<PlanInfo[]> {
    return ApiService.getJson('/action-center/annual-notice/plans/preview', {
      planIds
    });
  }

  static async sendAnnualNotices(
    planIds: [string],
    emailTemplate: string
  ): Promise<BulkAction[]> {
    return ApiService.getJson('/action-center/annual-notice/send', {
      emailTemplate,
      planIds
    });
  }
  static async bulkEmailPlans(
    effectiveDate: string,
    numberOfPlans: number
  ): Promise<BulkAction> {
    return ApiService.postJson('/action-center/bulk/email/plans', {
      effectiveDate,
      numberOfPlans
    });
  }

  static async bulkEmployerList(): Promise<BulkEmployerListResponse> {
    return ApiService.getJson('/action-center/bulk/employer-list');
  }

  static async bulkUploadEmployerList(
    documentData: FormData
  ): Promise<BulkEmployerValidateResponse> {
    return ApiService.postFormData(
      '/action-center/bulk/employer-list',
      documentData
    );
  }

  static async bulkWelcomeEmailsPlans(
    records: BulkWelcomeEmailTargetRecords
  ): Promise<Plan[]> {
    return ApiService.postJson(
      '/action-center/bulk/welcome-email/plans',
      records
    );
  }

  static async downloadEmployerListReport(
    activityId: number
  ): Promise<EmployerListReportResponse> {
    return ApiService.getJson('/action-center/bulk/employer-list/report', {
      id: activityId
    });
  }

  static async abandonEmployerList(activityId: number): Promise<unknown> {
    return ApiService.postJson('/action-center/bulk/employer-list/abandon', {
      activityId: activityId
    });
  }

  static async ingestEmployerList(
    activityId: number,
    insertEnabled: boolean,
    updateEnabled: boolean,
    exempt5500: boolean
  ): Promise<unknown> {
    return ApiService.postJson('/action-center/bulk/employer-list/ingest', {
      activityId,
      exempt5500,
      insertEnabled,
      updateEnabled
    });
  }
}

export { ActionCenterService };
