import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ActionCenterPreviewDialog from './ActionCenterPreviewDialog.component';
import { useBulkActionCenterConfig } from './useBulkActionCenterConfig.hook';
import { useVerticalStepperStyles } from './useVerticalStepperStyles';

export enum ActionTypes {
  BULK_UPLOAD_FILES = 'Bulk Upload Files',
  BULK_EMAIL = 'Bulk Email',
  BULK_EMPLOYER = 'Employer List Ingestion',
  BULK_WELCOME_EMAILS = 'Bulk Welcome Emails',
  ANNUAL_NOTICE = 'Annual Notices'
}

type ActionHistoryStepperFiltersProps = {
  onActionTypeChange?: (actionType: ActionTypes) => void;
};

const ActionHistoryStepperFilters: React.FunctionComponent<
  ActionHistoryStepperFiltersProps
> = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [actionType, setActionType] = useState<ActionTypes | ''>('');
  const [details, setDetails] = useState<Record<string, any>>({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const verticalStepperClasses = useVerticalStepperStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const bulkActionCenterConfig = useBulkActionCenterConfig(
    details,
    setDetails,
    setActiveStep,
    setDialogOpen
  );

  const onClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const newActionTypeKey = location.hash.replaceAll('#', '').toUpperCase();
      const newActionType =
        ActionTypes[newActionTypeKey as keyof typeof ActionTypes];
      setActionType(newActionType);
      if (props.onActionTypeChange) {
        props.onActionTypeChange(newActionType);
      }

      setDetails({});
      setActiveStep(newActionType ? 1 : 0);
    }
  }, [location, props.onActionTypeChange]);

  return (
    <Box
      className={verticalStepperClasses.root}
      data-testid='action-center-stepper-filters'
      sx={{ width: 400 }}>
      {bulkActionCenterConfig[actionType]?.previewDialog ? (
        bulkActionCenterConfig[actionType].previewDialog({
          data: bulkActionCenterConfig[actionType]?.previewData,
          handleClose: onClose,
          onConfirm: bulkActionCenterConfig[actionType]?.onConfirm,
          open: dialogOpen
        })
      ) : (
        <ActionCenterPreviewDialog
          columns={
            (bulkActionCenterConfig[actionType]?.isPreviewError
              ? bulkActionCenterConfig[actionType]?.previewErrorColumns
              : bulkActionCenterConfig[actionType]?.previewColumns) || []
          }
          handleClose={onClose}
          noDataMessage={
            bulkActionCenterConfig[actionType]?.noPreviewDataMessage
          }
          onConfirm={
            !bulkActionCenterConfig[actionType]?.isPreviewError
              ? bulkActionCenterConfig[actionType]?.onConfirm
              : undefined
          }
          open={dialogOpen}
          tableData={
            (bulkActionCenterConfig[actionType]?.isPreviewError
              ? bulkActionCenterConfig[actionType]?.previewErrorData
              : bulkActionCenterConfig[actionType]?.previewData) || []
          }
          title={
            bulkActionCenterConfig[actionType]?.isPreviewError
              ? 'Validation Failures'
              : 'Affected Plans'
          }
        />
      )}

      <Stepper activeStep={activeStep} orientation='vertical'>
        <Step
          active={0 <= activeStep}
          className={0 <= activeStep ? 'active-step' : 'disabled-step'}
          data-testid='type-step'>
          <StepLabel>Type</StepLabel>
          <StepContent>
            <FormControl fullWidth size='small'>
              <InputLabel id='select-action-type-label'>
                {actionType ? '' : 'Select...'}
              </InputLabel>
              <Select
                data-testid='select-action-type'
                label={actionType ? '' : 'Select...'}
                labelId='select-action-type-label'
                onChange={e => {
                  const enumKey =
                    Object.keys(ActionTypes)[
                      Object.values(ActionTypes).indexOf(
                        e.target.value as ActionTypes
                      )
                    ];
                  navigate(`${location.pathname}#${enumKey.toLowerCase()}`);
                }}
                sx={{ marginBottom: '10px' }}
                value={`${actionType}`}>
                {Object.entries(bulkActionCenterConfig)
                  .filter(([, config]) => config.visible)
                  .map(([actionType], index) => (
                    <MenuItem
                      key={actionType}
                      tabIndex={index}
                      value={actionType}>
                      {actionType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </StepContent>
        </Step>
        <Step
          active={1 <= activeStep}
          className={1 <= activeStep ? 'active-step' : 'disabled-step'}
          data-testid='details-step'>
          <StepLabel>Details</StepLabel>
          <StepContent>{bulkActionCenterConfig[actionType]?.step2}</StepContent>
        </Step>
        {(!bulkActionCenterConfig[actionType] ||
          (bulkActionCenterConfig[actionType] &&
            bulkActionCenterConfig[actionType]?.step3)) && (
          <Step
            active={2 <= activeStep}
            className={2 <= activeStep ? 'active-step' : 'disabled-step'}
            data-testid='target-step'>
            <StepLabel>Target</StepLabel>
            <StepContent>
              <Typography sx={{ marginBottom: '10px' }} variant='body2'>
                {bulkActionCenterConfig[actionType]?.step3}
              </Typography>
            </StepContent>
          </Step>
        )}
      </Stepper>
      {(!bulkActionCenterConfig[actionType] ||
        (bulkActionCenterConfig[actionType] &&
          bulkActionCenterConfig[actionType]?.step3)) && (
        <LoadingButton
          data-testid='preview-button'
          disabled={activeStep !== 3}
          loading={bulkActionCenterConfig[actionType]?.isPreviewLoading}
          onClick={bulkActionCenterConfig[actionType]?.onPreview}
          style={{ marginTop: '15px', width: '100%' }}
          variant='contained'>
          Preview
        </LoadingButton>
      )}
    </Box>
  );
};

export default ActionHistoryStepperFilters;
