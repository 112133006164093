import { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { Link } from '@/components/link';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { useSearchSubAccountsQuery } from '@/hooks/ops/useSearchSubAccountsQuery.hook';
import { SubAccountApiIncludeOption } from '@/models/ops/accounts/SubAccountApiIncludeOption.model';
import { SubAccountSearchRequest } from '@/models/ops/accounts/SubAccountSearchRequest.model';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Stack,
  Switch
} from '@mui/material';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';

import Decimal from 'decimal.js';
import { Field, Form, Formik } from 'formik';
import { FC, useMemo } from 'react';

type SearchFormValues = {
  query: string;
};

type SubAccountsProps = {
  accountType?: SubAccountType | SubAccountType[];
  customDefaultFilters?: Partial<SubAccountSearchRequest>;
  hideFilters?: boolean;
  parentAccountId?: string;
  planId?: string;
  urlStateParamName?: string;
};

export const SubAccounts: FC<SubAccountsProps> = props => {
  const parentAccountQuery = useParentAccountById({
    accountId: props.parentAccountId
  });

  const defaultParams: SubAccountSearchRequest = {
    hasDebitSubAccounts: false,
    parentAccountId: props.parentAccountId,
    planId: props.planId,
    query: '',
    ...props.customDefaultFilters
  };

  const [params, setParams] = useUrlStateParams<SubAccountSearchRequest>(
    defaultParams,
    props.urlStateParamName || 'subAccounts',
    value => JSON.stringify(value),
    value => {
      try {
        return JSON.parse(value);
      } catch {
        return defaultParams;
      }
    }
  );

  const searchSubAccountsQuery = useSearchSubAccountsQuery({
    ...params,
    accountType: props.accountType,
    include: [
      SubAccountApiIncludeOption.cashBalance,
      SubAccountApiIncludeOption.totalMarketValue
    ]
  });

  const handleSubmit = (values: SearchFormValues) => {
    setParams({
      ...params,
      ...values,
      page: 1
    });
  };

  const columnDefs: React.ComponentProps<typeof DataTable>['columnDefs'] =
    useMemo(
      () =>
        [
          {
            cellRenderer: (cellData: { data: SubAccountDto }) => (
              <Link
                target='_blank'
                to={`/ops/accounts/${cellData.data.parentAccountId}/sub-accounts/${cellData.data.subAccountId}`}>
                {cellData.data.subAccountId}
              </Link>
            ),
            field: 'subAccountId',
            headerName: 'Sub Account ID',
            minWidth: 180,
            resizable: true,
            sortable: true
          },
          parentAccountQuery.data?.accountType ===
            ParentAccountType.SuperOmnibus &&
            props.planId === undefined && {
              field: 'planId',
              headerName: 'Plan ID',
              minWidth: 120,
              sortable: true
            },
          {
            field: 'investorId',
            headerName: 'Part ID',
            minWidth: 120,
            sortable: true
          },
          props.parentAccountId === undefined &&
            props.planId === undefined && {
              autoHeight: true,
              cellRenderer: (cellData: { data: SubAccountDto }) => {
                const name =
                  cellData.data.parentAccount?.accountType ===
                    ParentAccountType.SuperOmnibus ||
                  cellData.data.parentAccount?.accountType ===
                    ParentAccountType.House
                    ? cellData.data.parentAccount.accountName
                    : cellData.data.plan?.name;

                return (
                  <DataTableStackCell
                    primary={name || ''}
                    secondary={`ID: ${cellData.data.parentAccount?.parentAccountId}`}
                  />
                );
              },
              field: 'parentAccountId',
              headerName: 'Parent Account',
              maxWidth: 330,
              minWidth: 330,
              sortable: true
            },
          {
            cellRenderer: (cellData: { data: SubAccountDto }) => {
              let participantName = '';
              if (
                cellData.data.participant?.firstName ||
                cellData.data.participant?.lastName
              ) {
                participantName = `${cellData.data.participant?.firstName} ${cellData.data.participant?.lastName}`;
              }
              return (
                <Link
                  target='_blank'
                  to={`/participants/${cellData.data?.investorId}/personal`}>
                  {participantName || '\u2014'}
                </Link>
              );
            },
            headerName: 'Participant Name',
            maxWidth: 260,
            minWidth: 260
            // this data is available only after paginating, so we need to keep the sorting disabled
          },
          {
            cellRenderer: (cellData: { data: SubAccountDto }) => {
              return cellData?.data?.fundingSource
                ? FundingSource[
                    cellData?.data?.fundingSource as keyof typeof FundingSource
                  ]
                : '\u2014';
            },
            field: 'fundingSource',
            headerName: 'Funding Source',
            maxWidth: 170,
            minWidth: 170
            // this data is available only after paginating, so we need to keep the sorting disabled
          },
          {
            field: 'totalMarketValue',
            headerName: 'Market Value',
            maxWidth: 260,
            type: 'numericColumn',
            valueFormatter: ({ value }) => formatters.formatDollars(value)
            // this is a calculated field, so we can't sort by it
          },
          {
            field: 'cashBalance',
            headerName: 'Cash Balance',
            maxWidth: 260,
            type: 'numericColumn',
            valueFormatter: ({ value }) => formatters.formatDollars(value)
            // this is a calculated field, so we can't sort by it
          },
          {
            field: 'createdAt',
            headerName: 'Created At',
            maxWidth: 190,
            minWidth: 190,
            sortable: true,
            valueFormatter: ({ value }: { value: string }) =>
              formatters.formatFromIsoDate(value)
          }
        ].filter(Boolean),
      []
    );

  const handleSortChange: React.ComponentProps<
    typeof DataTable
  >['onSortChanged'] = newSort => {
    if (!newSort || newSort.length === 0) {
      setParams({
        ...params,
        orderBy: undefined,
        orderByDirection: undefined
      });
    } else {
      setParams({
        ...params,
        orderBy: newSort[0].colId as keyof SubAccountDto,
        orderByDirection: newSort[0].sort
      });
    }
  };

  const handleHasDebitSubAccountsChange = (_event, newValue: boolean) => {
    setParams({
      ...params,
      hasDebitSubAccounts: newValue
    });
  };

  const handlePageChange: React.ComponentProps<
    typeof DataTable
  >['onPageChanged'] = (newPage, newSort) => {
    setParams({
      ...params,
      orderBy: (newSort?.[0]?.colId as keyof SubAccountDto) || params.orderBy,
      orderByDirection: newSort?.[0]?.sort || params.orderByDirection,
      page: newPage
    });
  };

  const handlePageSizeChange: React.ComponentProps<
    typeof DataTable
  >['onPageSizeChanged'] = newPageSize => {
    setParams({
      ...params,
      pageSize: newPageSize
    });
  };

  return (
    <>
      {!props.hideFilters && (
        <CardContent>
          <Formik
            initialValues={{
              query: ''
            }}
            onSubmit={(values: SearchFormValues) => handleSubmit(values)}>
            <Form>
              <Stack alignItems='center' direction='row' gap={2}>
                <FormControl fullWidth variant='outlined'>
                  <Field
                    as={OutlinedInput}
                    autoComplete='off'
                    data-testid='query-input'
                    endAdornment={<SearchIcon color='secondary' />}
                    inputProps={{
                      'aria-labelledby': 'search-accounts-heading'
                    }}
                    name='query'
                    placeholder='Account ID, VW Core Account ID, participant name, or Participant ID'
                    size='small'
                  />
                </FormControl>

                <Button
                  data-testid='all-sub-accounts-apply-button'
                  type='submit'
                  variant='outlined'>
                  Apply
                </Button>

                <FormControlLabel
                  control={
                    <Switch
                      checked={params.hasDebitSubAccounts}
                      onChange={handleHasDebitSubAccountsChange}
                    />
                  }
                  data-testid='filter-debit-sub-accounts-switch'
                  label='View accounts in debit only'
                  sx={{
                    minWidth: 260
                  }}
                />
              </Stack>
            </Form>
          </Formik>
        </CardContent>
      )}
      <Divider />

      <CardContent disablePadding loading={searchSubAccountsQuery.isFetching}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='data-sub-accounts'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-sub-accounts'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  searchSubAccountsQuery.data?.results === undefined
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          getRowStyle={params =>
            params.data.cashBalance &&
            new Decimal(params.data.cashBalance).lessThan(new Decimal(0)) && {
              backgroundColor: VestwellTheme.palette.warning.lightBg
            }
          }
          onPageChanged={handlePageChange}
          onPageSizeChanged={handlePageSizeChange}
          onSortChanged={handleSortChange}
          page={params.page}
          pageSize={params.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchSubAccountsQuery.data?.pagination?.total}
          rowData={searchSubAccountsQuery.data?.results || []}
          sort={
            params.orderBy
              ? [
                  {
                    colId: params.orderBy,
                    sort: params.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </>
  );
};
