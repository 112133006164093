import { Autocomplete, FormControl, TextField } from '@mui/material';

import { FC, useEffect, useState } from 'react';

interface ActionCenterDetailsAnnualNoticesProps {
  setDetails: (details: Record<string, any>) => void;
  details: Record<string, any>;
}

const ActionCenterDetailsAnnualNotices: FC<
  ActionCenterDetailsAnnualNoticesProps
> = props => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const templatesList = [
    '401k SH/ACA',
    '401k No SH/ACA',
    '403b SH/ACA',
    '403b No SH/ACA',
    'Unbundled 401k',
    'Unbundled 403b',
    'BBOO - 401k SH/ACA',
    'BBOO - 401k No SH/ACA',
    'BBOO - 403b SH/ACA',
    'BBOO - 403b No SH/ACA',
    'BBOO - Unbundled'
  ];

  useEffect(() => {
    if (!props.details.emailTemplate) {
      setInputValue('');
      setValue(null);
    }
  }, [props.details]);

  return (
    <div data-testid='action-center-annual-notices'>
      <FormControl fullWidth size='small'>
        <Autocomplete
          data-testid='details-notice-key-select'
          getOptionLabel={(option: string) => option}
          inputValue={inputValue}
          onChange={(_e, newValue: string) => {
            setValue(newValue);
            props.setDetails({
              ...props.details,
              emailTemplate: newValue
            });
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={templatesList}
          renderInput={params => (
            <TextField {...params} label='Email template' />
          )}
          size='small'
          sx={{ marginBottom: '20px' }}
          value={value}
        />
      </FormControl>
    </div>
  );
};
export default ActionCenterDetailsAnnualNotices;
