import { FormControl, TextField, Typography } from '@mui/material';

import { FC, useEffect, useState } from 'react';

interface ActionCenterDetailsAnnualNoticesProps {
  setDetails: (details: Record<string, any>) => void;
  details: Record<string, any>;
}

const ActionCenterTargetAnnualNotices: FC<
  ActionCenterDetailsAnnualNoticesProps
> = props => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (!props.details.emailTemplate) {
      setValue(null);
    }
  }, [props.details.plansList]);

  return (
    <div data-testid='action-center-target-annual-notices'>
      <FormControl fullWidth size='small'>
        <TextField
          fullWidth
          label='Plan IDs'
          minRows={8}
          multiline
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
            const splitList = event.target.value
              .split(/[\r?\n,]/)
              .filter(element => element);
            props.setDetails({
              ...props.details,
              planIds: splitList
            });
          }}
          value={value}
        />
        <Typography mb={2} variant='body2'>
          Comma or line-break delimited
        </Typography>
      </FormControl>
    </div>
  );
};
export default ActionCenterTargetAnnualNotices;
