import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';

import ActionCenterDetailsAnnualNotices from './ActionCenterDetailsAnnualNotices.component';
import ActionCenterTargetAnnualNotices from './ActionCenterTargetAnnualNotices.component';
import {
  BulkActionsConfig,
  previewColumns,
  previewErrorColumns
} from './useBulkActionCenterConfig.hook';

export const useAnnualNoticesConfig = (
  details: Record<string, any>,
  setDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS]
  });

  const annualNoticesPlansPreviewMutation = useMutation(
    ['ActionCenterService.annualNoticePlansPreview'],
    (planIds: [string]) =>
      ActionCenterService.annualNoticePlansPreview(planIds),
    {
      onError: () =>
        showSnackbar({
          message: 'There was an error previewing the plans',
          severity: 'error'
        }),
      onSuccess: () => {
        setDialogOpen(true);
      }
    }
  );

  const annualNoticesMutation = useMutation(
    ['ActionCenterService.sendAnnualNotices'],
    (data: { planIds: [string]; emailTemplate: string }) =>
      ActionCenterService.sendAnnualNotices(data.planIds, data.emailTemplate),
    {
      onError: () => {
        setDialogOpen(false);
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.sendAnnualNotices']
        });
        showSnackbar({
          message: 'There was an error emails sending for the selected plans',
          severity: 'error'
        });
      },
      onSuccess: () => {
        setDialogOpen(false);
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.sendAnnualNotices']
        });
        showSnackbar({
          message: 'Emails for the selected plans have been sent successfully.',
          severity: 'success'
        });
      }
    }
  );

  const updatedPreviewColumns = useMemo(
    () =>
      previewColumns.map(col =>
        col.id === 'planName' ? { ...col, id: 'name' } : col
      ),
    [previewColumns]
  );

  return useMemo<BulkActionsConfig>(
    () => ({
      isPreviewLoading: annualNoticesPlansPreviewMutation.isLoading,
      noPreviewDataMessage: 'There are no plans with the provided plan ids',
      onConfirm: () =>
        annualNoticesMutation.mutate({
          emailTemplate: details.emailTemplate,
          planIds: details.planIds
        }),
      onPreview: () =>
        annualNoticesPlansPreviewMutation.mutate(details.planIds),
      previewColumns: updatedPreviewColumns.filter(
        obj => obj.id !== 'effectiveDate'
      ),
      previewData: annualNoticesPlansPreviewMutation.data,
      previewErrorColumns,
      step2: (
        <ActionCenterDetailsAnnualNotices
          details={details}
          setDetails={newDetails => {
            setDetails(newDetails);
            setActiveStep(newDetails ? 3 : 1);
          }}
        />
      ),
      step3: (
        <>
          <ActionCenterTargetAnnualNotices
            details={details}
            setDetails={newDetails => {
              setDetails(newDetails);
            }}
          />
        </>
      ),
      visible: permissions.isAllowed
    }),
    [details, annualNoticesPlansPreviewMutation, permissions]
  );
};
