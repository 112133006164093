import { FileUploadTableError } from '@/components/file-upload-table/FileUploadTable.component';
import { PlanService } from '@/services/Plan.service';
import { formatSsn } from '@vestwell-frontend/helpers';

import { FormikContextType } from 'formik';
import { isEmpty, isString, omit, times } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

export const useConversionAgGrid = (planId: number): any => {
  const [gridRows, setGridRows] = useState<Record<string, any>[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const form = useRef<FormikContextType<unknown>>(null);

  const handleSubmit = async () => {};

  const validationSchema = yup
    .array()
    .of(
      yup
        .object()
        .when({
          is: ({ ...row }) => !isEmpty(row),
          otherwise: schema => schema,
          then: schema =>
            schema.shape({
              balance: yup
                .number()
                .transform((_value, originalValue) =>
                  Number(originalValue.replace(/,/g, ''))
                )
                .typeError('Balance must be numeric.')
                .moreThan(0, 'Balance must be greater than $0.')
                .required('Balance is required.'),
              fundingSource: yup
                .string()
                .oneOf(
                  [
                    'SD',
                    'PS',
                    'RC',
                    'AT',
                    'SHM',
                    'SHNE',
                    'SO',
                    'RO',
                    'RR',
                    'QC',
                    'QM',
                    'EM',
                    'QSHM',
                    'QSHNE',
                    'RS',
                    'PW'
                  ],
                  'Valid funding sources: SD, PS, RC, AT, SHM, SHNE, SO, RO, RR, QC, QM, EM, QSHM, QSHNE, RS, PW'
                )
                .required(
                  'Valid funding sources: SD, PS, RC, AT, SHM, SHNE, SO, RO, RR, QC, QM, EM, QSHM, QSHNE, RS, PW'
                ),
              ssn: yup
                .string()
                .required('SSN is required.')
                .test(
                  'Check for valid SSN format',
                  'Please enter a valid SSN',
                  (value?: string) => {
                    if (value) {
                      const ssnNoDashes = value.replace(/-/g, '');
                      const ssnDigits = new Set(ssnNoDashes.split(''));
                      return (
                        (formattedSSN.test(value) || plainSSN.test(value)) &&
                        ssnDigits.size > 1 &&
                        !blacklistedSSNs.includes(ssnNoDashes)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'Check if SSN exist within a plan',
                  'This SSN does not exist in plan.',
                  async value => {
                    let checkResult;
                    if (
                      planId &&
                      value &&
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                      new Set(value.replaceAll('-', '').split('')).size > 1
                    ) {
                      const ssnToValidate = formattedSSN.test(value)
                        ? value
                        : formatSsn(value);
                      checkResult = await PlanService.checkSsnWithinPlan(
                        planId,
                        ssnToValidate
                      );
                    } else {
                      await new Promise(resolve => {
                        setTimeout(resolve, 50);
                      });
                    }
                    return checkResult?.data ?? false;
                  }
                )
            })
        })
        .test({
          test: function (object) {
            const errorsList = [
              ['ssn', 'Each SSN/Funding Source combination must be unique'],
              [
                'fundingSource',
                'Each SSN/Funding Source combination must be unique'
              ]
            ]
              .map(([field, message]) => {
                if (
                  this.parent
                    .filter((o: any) => o !== object)
                    .some((o: any) => {
                      return (
                        o?.ssn &&
                        o?.fundingSource &&
                        o?.ssn === object?.ssn &&
                        o?.fundingSource === object?.fundingSource
                      );
                    })
                ) {
                  return this.createError({
                    message,
                    path: `${this.path}.${field}`
                  });
                }

                return true;
              })
              .filter(error => error instanceof yup.ValidationError);

            if (errorsList.length > 0) {
              return new yup.ValidationError(
                errorsList as yup.ValidationError[],
                object,
                this.path
              );
            }

            return true;
          }
        })
    )
    .min(1);

  const handleCellChange = useCallback(
    (key: string, value) => {
      const matched = key.match(/[^[]+(?=])/);
      if (key.toLowerCase().endsWith('state') && matched && +matched[0] > -1) {
        const valueToSave = isString(value)
          ? value.toUpperCase().slice(0, 2)
          : value;

        form.current?.setFieldValue(key, valueToSave);

        return;
      }

      if (key.match(/\[\d*].[a-zA-Z]*/)) {
        form.current?.setFieldValue(key, value);
      }
    },
    [form]
  );

  const gridErrors = useMemo(() => {
    const rowsData = gridRows;
    return Object.keys(errors).length
      ? rowsData.map<FileUploadTableError>((row, i) => {
          return errors[i]
            ? Object.keys(errors[i] ?? ({} as FileUploadTableError)).reduce(
                (acc, field: string) => {
                  const formError = errors[i] ?? {};
                  return {
                    ...acc,
                    ...{ [field]: formError[field] }
                  } as FileUploadTableError;
                },
                {} as FileUploadTableError
              )
            : {};
        })
      : [];
  }, [errors, gridRows]);

  const errorsCount = useMemo(() => {
    return (gridErrors || []).reduce((acc, field, index) => {
      return gridRows[index]?.pristine ? acc : acc + Object.keys(field).length;
    }, 0);
  }, [gridErrors, gridRows]);

  const handleErrors = useCallback(errorsObject => {
    setErrors(
      times(
        (form.current?.values as Record<string, unknown>[]).length,
        index => {
          return errorsObject[index] ?? {};
        }
      )
    );
  }, []);

  const handleLoad = (rows: Record<string, unknown>[]) => {
    form.current?.setValues([
      ...rows.map(row => ({
        ...row,
        uuid: uuidv4()
      })),
      { pristine: true, uuid: uuidv4() }
    ]);
  };

  const handleRowsChange = useCallback(newRows => {
    form.current?.setValues(newRows);

    // clear errors, they will be re-populated correctly by formik
    form.current?.setErrors([]);
  }, []);

  const rowCount = useMemo(() => {
    return gridRows.filter((row: any) => !row?.pristine).length;
  }, [gridRows]);

  const getDataForUpload = (initialData?: Record<string, any>) => {
    return gridRows
      .filter((row: any) => !row?.pristine)
      .map((row: any) => {
        return {
          ...initialData,
          ...omit(row, ['uuid'])
        };
      });
  };

  return {
    errorsCount,
    form,
    getDataForUpload,
    gridErrors,
    gridRows,
    handleCellChange,
    handleErrors,
    handleLoad,
    handleRowsChange,
    handleSubmit,
    rowCount,
    setGridRows,
    validationSchema
  };
};
