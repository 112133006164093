import Card, { CardContent, CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  Program,
  ProgramRule,
  RuleType,
  RuleValue
} from '@/models/ops/investments/Program.model';
import ProgramDetails, {
  DYNAMIC,
  FULL,
  validationSchemaWithInitial
} from '@/routes/ops/investments/ProgramDetails.component';
import ProgramDetailsAddPlan from '@/routes/ops/investments/ProgramDetailsAddPlan.component';
import ProgramDetailsPlans from '@/routes/ops/investments/ProgramDetailsPlans.component';
import {
  ExternalProviderInvestmentOptionResponse,
  ExternalProviderInvestmentOptionService
} from '@/services/ops/investments/ExternalProviderInvestmentOptionService.service';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useFormik } from 'formik';
import { pick } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

type ProgramDetailsTabProps = {
  programData: Program;
  refetchProgram: () => void;
};

export const ProgramDetailsTab: FC<ProgramDetailsTabProps> = props => {
  const snackbar = useSnackbar();
  const [planId, setPlanId] = useState(0);
  const epios = useQuery<ExternalProviderInvestmentOptionResponse[]>(
    [
      'ExternalProviderInvestmentOptionService.getExternalProviderInvestmentOptions'
    ],
    () =>
      ExternalProviderInvestmentOptionService.getExternalProviderInvestmentOptions(),
    {
      staleTime: Infinity
    }
  );

  const form = useFormik({
    initialValues: props.programData,
    onSubmit: async (values, actions) => {
      try {
        const program = { ...values };
        if (
          program.programRule.find(rule => rule.ruleValue === RuleValue.nli)
        ) {
          const nli = epios.data?.find(epio => epio.investmentType === 'nli');
          program.externalProviders = [
            {
              baseModelType: nli.baseModelType,
              externalInvestmentOptionId: nli.externalInvestmentOptionId,
              investmentType: nli.investmentType
            }
          ];
        }
        await ProgramService.putProgram(program.programId, program);
        snackbar.showSnackbar({
          message: 'Save Successful',
          severity: 'success'
        });
        actions.resetForm({ values: program });
        props.refetchProgram();
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed to save program: ${error}`,
          severity: 'error'
        });
      }
    },
    validationSchema: validationSchemaWithInitial(props.programData)
  });

  const externalProviderInvestmentOptions = useMemo(
    () =>
      props.programData.externalProviders?.reduce(
        (acc, provider) => {
          acc[provider.investmentType] =
            provider.targetSeriesInvestmentOptionId ||
            provider.riskSeriesInvestmentOptionId ||
            provider.fundLineupInvestmentOptionId;
          return acc;
        },
        {} as Record<string, number>
      ),
    [props.programData.externalProviders]
  );

  const setProgramValue = (field: string, value: any) => {
    switch (field) {
      case 'name': {
        form.setFieldValue('name', value);
        break;
      }
      case `programRule`: {
        const ruleType = value?.ruleType as RuleType;
        const ruleValue = value?.ruleValue as RuleValue;
        const checked = value?.checked as boolean;
        if (checked && ruleType === RuleType.default_type) {
          const arr = form.values.programRule
            .filter(
              rule =>
                rule.ruleValue !== ruleValue &&
                rule.ruleValue !== RuleValue.model
            )
            .map(rule => ({
              ...rule,
              ...{ ruleType: RuleType.available_type }
            }));
          const newDefault =
            form.values.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) ||
            form.values.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) ||
            ({ ruleType, ruleValue } as ProgramRule);

          form.setValues({
            ...form.values,
            defaultModelId:
              ruleValue === RuleValue.model
                ? form.values.defaultModelId
                : undefined,
            managedAccount:
              ruleValue === RuleValue.dynamic_goal
                ? form.values.managedAccount
                : undefined,
            programRule: arr.concat([{ ...newDefault, ruleType }])
          });
        } else if (checked) {
          const newRule =
            form.values.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) || ({ ruleType, ruleValue } as ProgramRule);
          form.setFieldValue(
            'programRule',
            form.values.programRule.concat([{ ...newRule, ruleType }])
          );
          if (ruleValue === RuleValue.target && form.values.managedAccount)
            form.setFieldValue('managedAccount', {
              ...form.values.managedAccount,
              fallbackRule: RuleValue.target
            });
        } else {
          form.setFieldValue(
            'programRule',
            form.values.programRule.filter(rule => rule.ruleValue !== ruleValue)
          );
          if (ruleValue === RuleValue.target && form.values.managedAccount)
            form.setFieldValue('managedAccount', {
              minimumAge: form.values.managedAccount?.minimumAge
            });
        }
        break;
      }
      case 'defaultModelId': {
        form.setFieldValue('defaultModelId', parseInt(value) || undefined);
        break;
      }
      case 'minimumAge': {
        const managedAccount = form.values.managedAccount
          ? form.values.managedAccount
          : {};
        form.setFieldValue('managedAccount', {
          ...managedAccount,
          minimumAge: parseInt(value) || undefined
        });
        break;
      }
      case 'fallbackRule': {
        const managedAccount = form.values.managedAccount
          ? form.values.managedAccount
          : {};
        form.setFieldValue('managedAccount', {
          ...managedAccount,
          fallbackRule: value as RuleValue
        });
        break;
      }
      case 'goalInvestmentSwitch': {
        if (value === FULL) {
          form.setFieldValue('managedAccount', undefined);
        } else if (value === DYNAMIC) {
          form.setFieldValue(
            'managedAccount',
            form.values.managedAccount || {}
          );
        }
        break;
      }
    }
  };

  unstable_usePrompt({
    message: 'Choose cancel to avoid losing the unsaved changes.',
    when: form.dirty
  });

  return (
    <Grid container spacing={4}>
      <Grid lg={5} xl={4} xs={12}>
        <Card>
          <CardHeader
            actionButtonsProps={[
              {
                disabled: !(form.dirty && form.isValid),
                label: 'Update',
                onClick: form.submitForm,
                variant: 'contained'
              }
            ]}
            title='Program Information'
          />
          <CardContent>
            <ProgramDetails
              formikErrors={form.errors}
              program={form.values}
              setProgramValueCallback={setProgramValue}
              typeIds={{
                externalProviderInvestmentOptions,
                ...pick(form.values, [
                  'fundLineupId',
                  'goalSeriesId',
                  'riskSeriesId',
                  'targetDateSeriesId'
                ])
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={7} xl={8} xs={12}>
        <ProgramDetailsAddPlan
          programId={form.values.programId}
          setPlanId={setPlanId}
        />
        <ProgramDetailsPlans
          planId={planId}
          programId={form.values.programId}
        />
      </Grid>
    </Grid>
  );
};

ProgramDetailsTab.displayName = 'ProgramDetailsTab';
